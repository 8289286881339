import ContentsToReact from '@/components/ContentsToReact/ContentsToReact';
import GenericModals from '@/components/GenericModals/GenericModals';
import Page from '@/components/Page/Page';
import { extractContents } from '@/helpers/extractContents';
import extractPageMetaFromRouteData from '@/helpers/extractPageMetaFromRouteData';
import { PageModel } from '@/models/page';
import BackToTopButton from '@/uikit/components/BackToTopButton/BackToTopButton';
import DistrictHeatLPHeroSection from '@/uikit/components/DistrictHeatLPHeroSection/DistrictHeatLPHeroSection';

import { useInitialPageDataLayer } from '@/hooks/useInitialPageDataLayer/useInitialPageDataLayer';
import EnercityFooter from '@/uikit/components/EnercityFooter/EnercityFooter';
import Layout from '@/uikit/components/Layout/Layout';
import PromoBanner from '@/uikit/components/PromoBanner/PromoBanner';
import usePromoBanner from '@/uikit/components/PromoBanner/usePromoBanner';
import { CmsComponentsContext } from '../CmsComponentsContext';
import cmsComponents from './DistrictHeatLandingPage.cmsComponents';
import type { DistrictHeatLandingPageProps } from './interfaces';

const DistrictHeatLandingPage = (
  pageData: PageModel<DistrictHeatLandingPageProps>
): JSX.Element => {
  const meta = extractPageMetaFromRouteData(pageData);

  const { isBannerHidden } = usePromoBanner(pageData.props.promoBanner);

  const {
    extractedContents: [noticeBarArea, headerArea],
    restContents,
  } = extractContents(['notice-bar-area', 'header'], pageData.contents);

  useInitialPageDataLayer();

  return (
    <CmsComponentsContext.Provider value={cmsComponents}>
      {noticeBarArea && <ContentsToReact contents={noticeBarArea} />}

      <Layout>
        <Page meta={meta}>
          {headerArea && <ContentsToReact contents={headerArea} />}

          <DistrictHeatLPHeroSection />

          {restContents && <ContentsToReact contents={restContents} />}
        </Page>

        <BackToTopButton
          isFixed={
            pageData.props.promoBannerVisible === 'promoBanner' &&
            !isBannerHidden
          }
        />

        <EnercityFooter />
      </Layout>

      {pageData.props.promoBannerVisible === 'promoBanner' &&
        pageData.props.promoBanner && (
          <PromoBanner banner={pageData.props.promoBanner} />
        )}

      <GenericModals modals={pageData.modals} />
    </CmsComponentsContext.Provider>
  );
};

export default DistrictHeatLandingPage;

import { CmsComponentsBase } from '@/cmsComponents/base';
import Container from '@uikit/components/Container/Container';
import Section from '@uikit/components/Section/Section';
import SectionVisual from '@uikit/components/SectionVisual/SectionVisual';
import TwoColumnContainer from '@uikit/components/TwoColumnContainer/TwoColumnContainer';

const cmsComponents = {
  ...CmsComponentsBase,
  'district-heat-lp-container': Container,
  'district-heat-lp-section-visual': SectionVisual,
  'district-heat-lp-section': Section,
  'district-heat-lp-two-column-container': TwoColumnContainer,
};

export default cmsComponents;
